
import Vue from 'vue';
export default Vue.extend({
  name: 'settings',

  metaInfo: {
    title: 'Settings -'
  },

  data: () => ({
    tab: null,
    items: [
      {
        route: { name: 'tasks-management' },
        name: 'Task Management',
        order: 1
      }
    ]
  })
});
